import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { IPhone } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>
  If you are not able to get the GPS location working for Trailguide on the
  iPhone, the reason might be that you have location services turned off for
  Safari Websites (or the browser you are using).
    </p>
    <br />
    <p><a parentName="p" {...{
        "href": "/howto/install"
      }}>{`How do I install Trailguide as an App?`}</a></p>
    <p><a parentName="p" {...{
        "href": "/howto/navigation"
      }}>{`How do I use Trailguide for navigation?`}</a></p>
    <br />
    <div className="flex flex-wrap justify-left">
  <IPhone alt="Location failed" img="/howto/gps-iphone-1.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="1) If you have not enabled GPS locatiom services for your phone or for Safari Websites (or the browser you are currently using), you will get an error message like this" mdxType="IPhone" />
  <IPhone alt="Privacy menu" img="/howto/gps-iphone-2.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="2) Go to the Privacy menu in the Settings app" mdxType="IPhone" />
  <IPhone alt="Location Services menu" img="/howto/gps-iphone-4.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="3) Access the Location Services menu" mdxType="IPhone" />
  <IPhone alt="Location Services must be on" img="/howto/gps-iphone-3.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="4) Make sure the Location Services is turned ON" mdxType="IPhone" />
  <IPhone alt="Find Safari" img="/howto/gps-iphone-5.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="5) Find Safari Websites in the list of apps (or Chrome, Firefox, Opera or any other browser you are using to access Trailguide)" mdxType="IPhone" />
  <IPhone alt="While Using the App" img="/howto/gps-iphone-6.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="6) Set it to 'While Using the App'" mdxType="IPhone" />
  <IPhone alt="Voila!" img="/howto/gps-iphone-7.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="7) Voila! The GPS location should now work" mdxType="IPhone" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      